import React from "react"
import styled from "styled-components"
import { Headline, HeadlineJp } from "../modules/headlines"
import customMedia from "../utility/media"
import colors from "../utility/colors"
import InviewMonitor from "react-inview-monitor"
import { AnimatedWrap } from "../modules/animatedwrap"
import RecruitMain from "../images/photos/RecruitCarrer.png"
import RecruitNewGraduate from "../images/photos/RecruitNewGraduate.png"

export class Recruit extends React.Component {
  render() {
    return (
      <AnimatedWrap>
        <InviewMonitor classNameInView="is-inview" id="recruit">
          <RecruitWrap className="wrap">
            <Headline id="recruit">RECRUIT</Headline>
            <HeadlineJp>採用情報</HeadlineJp>
            <JobLinks>
              <RecruitCard
                className="recruit-card"
                href="https://opt.snar.jp/entry.aspx?entryid=0a8d740c-9b11-4b0f-9d30-7d26dcbebee9"
                target="_blank"
                rel="noopener"
              >
                <TextWrap>
                  <div>
                    <h3 className="title">新卒採用</h3>
                    <p className="text">2026卒採用のエントリー受付中</p>
                  </div>
                </TextWrap>
                <div className="recruit-image-wrap">
                  <img
                    className="recruit-image"
                    alt="新卒採用のご応募はこちらから"
                    src={RecruitNewGraduate}
                  />
                </div>
              </RecruitCard>
              <RecruitCard
                className="recruit-card"
                
                target="_blank"
                rel="noopener"
              >
                <TextWrap>
                  <div>
                    <h3 className="title">中途採用</h3>
                    <p className="text">
                      現在募集しておりません
                    </p>
                  </div>
                </TextWrap>
                <div className="recruit-image-wrap">
                  <img
                    className="recruit-image"
                    alt="中途採用のご応募はこちらから"
                    src={RecruitMain}
                  />
                </div>
              </RecruitCard>
            </JobLinks>
          </RecruitWrap>
        </InviewMonitor>
      </AnimatedWrap>
    )
  }
}

const TextWrap = styled.div`
  border-left: 8px solid ${props => colors.attention};
  padding: 16px;
  display: flex;
  align-items: center;
  background: #fff;

  ${customMedia.greaterThan("main")`
    width: 100%;
    height: 100%;
  `}

  & .title {
    display: inline-block;
    margin-right: 8px;
    text-decoration: none;
    color: #000;
    font-size: 1.17rem;
  }

  & .text {
    text-decoration: none;
    color: #000;
    ${customMedia.greaterThan("medium")`
      font-size: 1rem;
    `}
  }

  & .recruit-notes {
    text-align: left;
    font-size: 0.5rem;
    color: #000;
  }
`

const RecruitWrap = styled.div`
  padding: 48px 0;
  background: linear-gradient(
    -25deg,
    #fff 50%,
    ${props => colors.complement} 50%,
    ${props => colors.complement} 80%,
    #fff 80%
  );
`

const JobLinks = styled.div`
  width: 80%;
  margin: 16px auto;
  text-align: center;
  max-width: 960px;

  ${customMedia.greaterThan("medium")`
    display: grid;
    grid-template-columns: repeat(2, 48%);
    grid-gap: 16px;
    justify-content: space-around
  `}
`

const RecruitCard = styled.a`
  width: 100%;
  background: #fff;
  display: flex;
  margin: 16px auto;
  overflow: hidden;

  & .recruit-image-wrap {
    display: flex;
    margin-left: auto;
    width: 150px;
    ${customMedia.greaterThan("main")`
      width: 250px;
    `}
  }

  & .recruit-image {
    width: 150px;
    ${customMedia.greaterThan("main")`
      width: 250px;
    `}
  }
`
