import React from "react"
import styled from "styled-components"
import LocationSendai from "../images/photos/LocationSendai.png"
import LocationTokyo from "../images/photos/LocationTokyo.jpg"
import { Headline, HeadlineJp } from "../modules/headlines"
import customMedia from "../utility/media"
import colors from "../utility/colors"
import InviewMonitor from "react-inview-monitor"
import { AnimatedWrap } from "../modules/animatedwrap"

export const Location = () => (
  <AnimatedWrap>
    <InviewMonitor classNameInView="is-inview">
      <LocationWrap className="wrap">
        <Headline id="location">LOCATION</Headline>
        <HeadlineJp>開発拠点</HeadlineJp>
        <AreaWrap className="first">
          <AreaImage src={LocationTokyo} alt="東京オフィス" />
          <Area>
            <h3 className="title">東京オフィス</h3>
            <InviewMonitor classNameInView="is-inview">
              <p className="text">
                市ヶ谷にある東京本社では各プロダクトチームで開発が進められています。パーティションのないオープンなスペースで、スタンディングデスクやミーティングエリアもあるので、エンジニア同士やビジネスサイドと気軽にコミュケーションを取りながら、開発を進めることができます。
              </p>
              <address>
                <span>〒102-0081</span>
                <div>東京都千代田区四番町6　東急番町ビル</div>
              </address>
              <div className="station">
                <span className="station-label">最寄り駅:</span>
                <ul className="station-list">
                  <li>JR 市ヶ谷駅</li>
                  <li>都営新宿線 市ヶ谷駅</li>
                  <li>東京メトロ有楽町線 市ヶ谷駅</li>
                  <li>東京メトロ南北線 市ヶ谷駅</li>
                </ul>
              </div>
            </InviewMonitor>
          </Area>
        </AreaWrap>
        <AreaWrap className="second">
          <AreaImage src={LocationSendai} alt="仙台ラボ" />
          <Area>
            <h3 className="title">仙台ラボラトリ</h3>
            <InviewMonitor classNameInView="is-inview">
              <p className="text">
                仙台駅近くにある仙台ラボラトリでは、東京仙台横断のプロダクトチームで開発が進められています。小規模な拠点ですが、会議室にはTV会議システム完備し、東京本社との適宜オンラインで接続し、距離を感じることなく仕事を進めることが可能です。
              </p>
              <address className="address">
                <span>〒983-0852</span>
                <div>
                  宮城県仙台市宮城野区榴岡3丁目4番1号　アゼリアヒルズ13階
                </div>
              </address>
              <div className="station">
                <span className="station-label">最寄り駅:</span>
                <ul className="station-list">
                  <li>JR 仙台駅</li>
                  <li>地下鉄東西線　宮城野通駅</li>
                </ul>
              </div>
            </InviewMonitor>
          </Area>
        </AreaWrap>
      </LocationWrap>
    </InviewMonitor>
  </AnimatedWrap>
)

const Area = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 8px;

  & .title {
    font-weight: bold;
    font-size: 1.2rem;
  }

  & .station {
    display: flex;
  }

  & .station-label {
    font-weight: bold;
  }

  & .station-list {
    padding-left: 8px;
  }

  ${customMedia.greaterThan("main")`
    padding: 40px;
  `}

  & .text,
  & .address,
  & .station {
    opacity: 0;
  }

  & .is-inview {
    & .text,
    & .address,
    & .station {
      opacity: 1;
      transition: opacity 0.2s 0.2s;
    }
  }
`

const AreaImage = styled.img`
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 0 auto;
  ${customMedia.greaterThan("main")`
    width: 50%;
    margin: 0 auto;
  `}
`

const AreaWrap = styled.div`
  max-width: 1200px;
  margin: 20px auto 0 auto;

  ${customMedia.greaterThan("main")`
    display: flex;
    align-items: start;
    margin: 0 auto 0 auto;
  `}

  &.first {
    margin-top: 64px;
  }

  &.second {
    flex-direction: row-reverse;
  }
`

const LocationWrap = styled.div`
  position: relative;
  padding: 48px 0 0;
  background: linear-gradient(
    -25deg,
    #fff 50%,
    ${props => colors.complement} 50%,
    ${props => colors.complement} 80%,
    #fff 80%
  );
`
