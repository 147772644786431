import React from "react"
import styled from "styled-components"
import colors from "../utility/colors"
import { WorkStyle } from "../components/workstyle"
import { Events } from "./events"
import { Magazine } from "./magazine"
import InviewMonitor from "react-inview-monitor"
import { AnimatedWrap } from "../modules/animatedwrap"
import { OneDays } from "./onedays"

const SystemsBase = styled.div`
  width: 100vw;
  background: linear-gradient(
    -25deg,
    ${props => colors.primary} 0%,
    ${props => colors.primary} 50%,
    ${props => colors.deep} 50%,
    ${props => colors.deep} 80%,
    ${props => colors.primary} 80%
  );
`

export const Systems = () => (
  <AnimatedWrap>
    <InviewMonitor classNameInView="is-inview">
      <SystemsBase className="wrap">
        <WorkStyle />
        <Events />
      </SystemsBase>
    </InviewMonitor>
  </AnimatedWrap>
)
