import React, { useState, useEffect } from "react"
import styled from "styled-components"
import colors from "../utility/colors"
import LogoWhite from "../images/LogoWhite.svg"
import { Header } from "../modules/header"
import Slide01 from "../images/slider/Slide01.png"
import Slide02 from "../images/slider/Slide02.png"
import Slide03 from "../images/slider/Slide03.png"
import { useSpring, animated, useTransition } from "react-spring"
import customMedia from "../utility/media"
import InviewMonitor from "react-inview-monitor"

export class Whatis extends React.Component {
  render() {
    return (
      <AnimatedWrap>
        <InviewMonitor classNameInView="is-inview">
          <WhatisWrap className="wrap">
            <Header />
            <Content id="top">
              <AnimatedContent />
              <AnimatedImage />
            </Content>
            <Scroll>◀ SCROLL</Scroll>
          </WhatisWrap>
        </InviewMonitor>
      </AnimatedWrap>
    )
  }
}

const AnimatedWrap = styled.div`
  & .wrap {
    background-size: 600vw;
  }
  & .is-inview {
    .wrap {
      background-size: 100vw;
      transition: all 0.6s 1s;
    }
  }
`

const AnimatedContent = () => {
  const props = useSpring({
    from: { opacity: "0" },
    to: async next => {
      await next({ opacity: "1" })
    },
    delay: 1200,
  })
  return (
    <div className="main-content">
      <img className="main-logo" src={LogoWhite} alt="Opt Technologies" />
      <animated.p className="main-text" style={props}>
        Opt Technologies
        は、株式会社デジタルホールディングス傘下、株式会社オプトのエンジニア組織です。
        <br />
        永続的な顧客の事業成長への貢献を通じて、‟産業変革=IX：Industrial Transformation®”を目指します。
      </animated.p>
    </div>
  )
}

const AnimatedImage = () => {
  const props = useSpring({
    from: { width: "0%" },
    to: async next => {
      await next({ width: "100%" })
    },
  })
  return (
    <ImageWrap className="script-box" style={props}>
      <Images />
    </ImageWrap>
  )
}

const slides = [
  { id: 0, url: Slide01 },
  { id: 1, url: Slide02 },
  { id: 2, url: Slide03 },
]

const Images = () => {
  const [index, set] = useState(0)
  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0, left: "-200vw", top: "-200vh" },
    enter: { opacity: 1, left: "0", top: "0" },
    leave: { opacity: 0.8, left: "0", top: "0" },
    config: { mass: 1, tension: 120, friction: 16 },
  })
  useEffect(
    () => void setInterval(() => set(state => (state + 1) % 3), 5000),
    []
  )
  return transitions.map(({ item, props, key }) => (
    <animated.div
      key={key}
      className="bg"
      style={{ ...props, backgroundImage: `url(${item.url})` }}
    />
  ))
}

const Content = styled.div`
  & .main-content {
    width: 80%;
    margin: 40px auto 32px;
  }

  & .main-logo {
    width: 50vw;
    max-width: 360px;
    display: block;
    margin-bottom: 16px;
    background: ${props => colors.primary};
  }

  & .main-text {
    margin: 40px auto 0;
    color: white;
    line-height: 2;
    opacity: 0;
  }

  ${customMedia.greaterThan("main")`
    display: grid;
    grid-template-columns: 1fr 1fr;

    > span {
      margin-top: 10vh;
    }

    & .main-content {
      width: 65%;
      margin-top: 38vh;
      margin-right: 10%;
    }

    & .main-logo {
      width: 100%;
      max-width: 280px;
      margin-left: 0;
    }

    & .main-text {
      margin-left: 0;
      max-width: 480px
    }
  `}

  ${customMedia.greaterThan("large")`
      & .main-content {
      width: 80%;
      margin-top: 30vh;
      margin-right: 0;
    }
  `}
`

const ImageWrap = styled(animated.div)`
  width: 100vw;
  height: 60vh;
  transform: skew(20deg);
  overflow: hidden;
  position: relative;

  > div {
    height: 50vh;
    width: 150vw;
    background-size: cover;
    margin: -8vh 0 8vh -10vw;
    position: absolute;
    transform: skew(-20deg);
    top: 0;

    ${customMedia.greaterThan("main")`
      width: 90vw;
      height: 108vh;
      margin: -10vh 0 0 -52vw;
      transform: skew(-40deg);
    `}
  }

  ${customMedia.greaterThan("main")`
    width: 50vw;
    height: 100vh;
    margin-left: 20vw;
    transform: skew(40deg);
  `}

  ${customMedia.greaterThan("semilarge")`
    margin-left: 0;

    > div {
      margin: -8vh 0 0 -32vw;
    }
  `}
`

const WhatisWrap = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${props => colors.primary};
  overflow: hidden;
  background: linear-gradient(
    -25deg,
    ${props => colors.deep} 0%,
    ${props => colors.deep} 32%,
    ${props => colors.primary} 32%
  );
`

const Scroll = styled.div`
  color: #fff;
  transform: rotate(-90deg);
  position: absolute;
  left: -42px;
  bottom: 42px;
  background: ${props => colors.attention};
  width: 120px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  font-size: 0.8rem;
`
