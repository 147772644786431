import React from "react"
import styled from "styled-components"
import { Button } from "../modules/buttons"
import ImageHackathon from "../images/photos/EventHackathon.jpg"
import ImageInner from "../images/photos/EventInner.jpg"
import ImageTech from "../images/photos/EventTech.jpg"
import customMedia from "../utility/media"
import InviewMonitor from "react-inview-monitor"

export class Events extends React.Component {
  render() {
    return (
      <>
        <Heading>技術イベント</Heading>
        <Wrap>
          <Card>
            <div className="card-image-wrap">
              <img
                className="card-image"
                src={ImageHackathon}
                alt="社内勉強会"
              />
            </div>
            <div className="card-title">社内勉強会</div>
            <div className="card-desc">
              <InviewMonitor classNameInView="is-inview">
                全体の勉強会、有志のもの合わせると大小様々な社内勉強会を開催しています。全体の勉強会はテーマ自由！発表者の興味関心によって、情報技術に関することから人文科学系まで様々なテーマがあります。
              </InviewMonitor>
            </div>
          </Card>
        </Wrap>
      </>
    )
  }
}

const Heading = styled.h3`
  color: #fff;
  text-align: center;
`

const Wrap = styled.div`
  width: 80%;
  max-width: 960px;
  margin: 0 auto;
  grid-column-gap: 16px;
  ${customMedia.greaterThan("main")`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  `}
`

const Card = styled.div`
  margin: 32px auto;

  & .card-image-wrap {
    width: 92px;
    height: 92px;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    ${customMedia.greaterThan("main")`
      width: 200px;
      height: 200px;
    `}
    overflow: hidden;
  }

  & .card-image {
    height: 92px;
    ${customMedia.greaterThan("main")`
      height: 200px;
    `}
  }

  & .card-title {
    margin-top: 24px;
    color: #fff;
    font-weight: bold;
    text-align: center;
  }

  & .card-desc {
    color: #fff;
    font-size: 0.8rem;
    text-align: center;
    max-width: 256px;
    margin: 16px auto 32px;

    > div {
      opacity: 0;
    }

    > .is-inview {
      opacity: 1;
      transition: opacity 0.2s 0.2s;
    }
  }

  & .read-more {
    margin: 16px auto;
    padding: 12px 32px;
    color: #000;
    text-align: center;
    &:hover {
      text-decoration: none;
      color: #000;
    }
  }
`
